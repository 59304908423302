import { useState, useEffect } from 'react';
import { ethers } from "ethers";
import './SignUp.css';  // Your CSS file for styling
import contractData from "../../contracts/contract.json";
import signin from '../../assets/signin.jpg';
const SignUp = () => {
    const contractAddress = '0xc4eCaEEE6d4b562b6d7003dd38563a2816fb2B2F'; // Your contract address
    const [message, setMessage] = useState('');
    const [config, setConfig] = useState(null);  // State to store the config values
    const [error, setError] = useState(null);  // State to handle errors
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [walletAddress, setWalletAddress] = useState('');

    // Fetch the config from the Flask API when the component mounts
    // useEffect(() => {
    //     const fetchConfig = async () => {
    //         try {
    //             const response = await fetch('https://ai.medicledger.com/api/config');
    //             const data = await response.json();
    //             setConfig(data);
    //         } catch (err) {
    //             setError('Failed to fetch config');
    //         }
    //     };
    //     fetchConfig();
    // }, []);

    // const handleSignUp = async (event) => {
    //     event.preventDefault();

    //     if (!config) {
    //         alert("Configuration not loaded yet.");
    //         return;
    //     }

    //     const { POLYGON_RPC_URL, WALLET_PRIVATE_KEY } = config;
    //     const provider = new ethers.JsonRpcProvider(POLYGON_RPC_URL);
    //     const wallet = new ethers.Wallet(WALLET_PRIVATE_KEY, provider);
    //     const contract = new ethers.Contract("0xfc1D316799c91C10C98c2Ddd7E3324a3a9B1aF96", contractData.abi, wallet);

    //     const fullName = event.target.fullName.value;
    //     const email = event.target.email.value;
    //     const password = event.target.password.value;
    //     const role = event.target.role.value;

    //     try {
    //         const tx = await contract.signUp(fullName, email, password, role);
    //         console.log('Transaction sent:', tx.hash);

    //         await tx.wait();
    //         console.log("Transaction mined. User signed up!");
    //         alert("User registered successfully!");
    //     } catch (error) {
    //         console.error("Error signing up user:", error);
    //         alert("Registration failed. Check console for details.");
    //     }
    // };

    // const connectMetaMask = async () => {
    //     if (typeof window.ethereum === 'undefined') {
    //         alert("MetaMask is not installed. Please install MetaMask.");
    //         return;
    //     }

    //     try {
    //         // Request account access
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });

    //         // Initialize ethers provider
    //         const provider = new ethers.BrowserProvider(window.ethereum);
    //         const signer = await provider.getSigner();

    //         // Get wallet address
    //         const address = await signer.getAddress();
    //         console.log("Connected wallet address:", address);
    //         setWalletAddress(address);
    //     } catch (err) {
    //         console.error("Error connecting to MetaMask", err);
    //     }
    // };

    // const handleSignUp = async (e) => {
    //     e.preventDefault();

    //     if (!walletAddress) {
    //         alert("Please connect your MetaMask wallet first.");
    //         return;
    //     }

    //     try {
    //         console.log('inside try block')
    //         // Initialize contract with the connected signer
    //         const provider = new ethers.BrowserProvider(window.ethereum);
    //         const signer = await provider.getSigner();
    //         const contract = new ethers.Contract(contractAddress, contractData, signer);

    //         // Call the signUp function on the smart contract
    //         const tx = await contract.signUp(fullName, email, password, role);
    //         console.log("Transaction sent:", tx.hash);

    //         // Wait for transaction to be mined
    //         await tx.wait();
    //         console.log("Transaction mined. User signed up!");
    //     } catch (error) {
    //         console.error("Error signing up user:", error);
    //     }
    // };


    const connectMetaMask = async () => {
        if (window.ethereum) {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = await provider.getSigner();
                const address = await signer.getAddress();
                setWalletAddress(address); // Store connected wallet address
                return signer; // Return the signer object for further use
            } catch (err) {
                setError("MetaMask connection failed. Please try again.");
                console.error("MetaMask Error:", err);
            }
        } else {
            // Check if the user is on mobile or desktop
            if (isMobile()) {
                // For mobile users without MetaMask
                setMessage('Please install MetaMask on your mobile browser or use the MetaMask app.');
                window.open('https://metamask.app.link/', '_blank'); // Open MetaMask app link for mobile
            } else {
                // For desktop users without MetaMask
                setMessage('Please install MetaMask to continue.');
                window.open('https://metamask.io/download.html', '_blank'); // Redirect to MetaMask download page
            }
        }
    };
    const isMobile = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    };

    const handleSignUp = async (event) => {
        event.preventDefault();

        const signer = await connectMetaMask();
        if (!signer) return; // If connection to MetaMask fails, do not proceed

        try {
            // Log contract data to ensure it's in correct format
            console.log("Contract ABI: ", contractData);

            // Create a contract instance
            const contract = new ethers.Contract(contractAddress, contractData.abi, signer);
            console.log("Contract: ", contract); // Check the contract instance

            // Call the signUp method
            const tx = await contract.signUp(fullName, email, password, role);
            console.log("Transaction sent:", tx.hash);

            // Wait for the transaction to be mined
            await tx.wait();
            console.log("Transaction mined. User signed up!");

            // Optionally, show success message or redirect user after signup
            setError(""); // Clear any previous errors
            alert("Sign up successful!");
        } catch (err) {
            setError("Error during sign-up. Please try again.");
            console.error("SignUp Error:", err);
        }
    };

    return (
        <div className="signup-container">
            <section className="left-section">
                <img src={signin} alt="MedicLedger Vision" className="signup-image" />
            </section>
            <section className="right-section">
                <div className="form-container">
                    <h2>Sign Up for MedicLedger</h2>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <form onSubmit={handleSignUp}>
                        <input
                            type="text"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="form-group">
                            <label htmlFor="role">Role</label>
                            <select
                                id="role"
                                name="role"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="farmer">Farmer</option>
                                <option value="manufacturer">Manufacturer</option>
                                <option value="distributor">Distributor</option>
                            </select>
                        </div>
                        <button type="submit" className="cta-button-signup">Complete the Sign Up</button>
                    </form>
                    {walletAddress && <p>Connected Wallet: {walletAddress}</p>}
                </div>
            </section>
        </div>
    );
};

export default SignUp;
