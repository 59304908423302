import React, { useState, useEffect, useRef } from 'react';
import './AiAssistant.css';  // Styling for the assistant page

const AiAssistant = () => {
    const [email, setEmail] = useState('');
    const [query, setQuery] = useState('');
    const [messages, setMessages] = useState([]); // Track all messages (user and assistant)
    const [isEmailEntered, setIsEmailEntered] = useState(false);

    // Ref to scroll the chat to the bottom
    const chatContainerRef = useRef(null);

    useEffect(() => {
        // Load stored messages from sessionStorage
        const storedMessages = JSON.parse(sessionStorage.getItem('messages'));
        if (storedMessages) {
            setMessages(storedMessages);
        }
    }, []);

    useEffect(() => {
        // Save messages to sessionStorage whenever they change
        sessionStorage.setItem('messages', JSON.stringify(messages));

        // Scroll to the bottom of the chat after each new message
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        // Call API to save email
        const response = await fetch('https://ai.medicledger.com/api/save-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            setIsEmailEntered(true);
        } else {
            alert('Error saving email');
        }
    };

    const handleQuerySubmit = async (e) => {
        e.preventDefault();

        // Add user query to messages
        const newMessages = [...messages, { role: 'user', content: query }];
        setMessages(newMessages);
        setQuery(''); // Clear query input

        // Fetch AI response and add it to messages
        const response = await fetch('https://ai.medicledger.com/api/ask-question', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
        });

        const data = await response.json();

        // Add assistant's response to messages
        const updatedMessages = [...newMessages, { role: 'assistant', content: data.answer }];
        setMessages(updatedMessages);
    };

    return (
        <div className="ai-assistant">
            <h1>AI Assistant</h1>
            {!isEmailEntered ? (
                <div className="email-entry">
                    <h3>Enter your email to try AI Assistant</h3>
                    <form onSubmit={handleEmailSubmit}>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit">Try It</button>
                    </form>
                </div>
            ) : (
                <div className="query-section">
                    <h3>Ask a Question</h3>
                    <form onSubmit={handleQuerySubmit}>
                        <input
                            type="text"
                            placeholder="Ask something like: 'What is my current inventory?'"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            required
                        />
                        <button type="submit">Ask</button>
                    </form>
                    <div className="chat" ref={chatContainerRef}>
                        {messages.length > 0 && (
                            <div className="chat-history">
                                {messages.map((message, index) => (
                                    <div key={index} className={`message ${message.role}`}>
                                        <strong>{message.role === 'user' ? 'You' : 'Assistant'}: </strong>
                                        <p>{message.content}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AiAssistant;
