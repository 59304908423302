import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HomePage from './components/HomePage/HomePage';
import AboutUs from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import Features from './components/Features/Features';
import Onboarding from './components/Onboarding/Onboarding'; // Onboarding page component
import Tracking from './components/Tracking/Tracking'; // Tracking page component
import Compliance from './components/Compliance/Compliance';
import SignUp from './components/SignUp/SignUp'; // SignUp page component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './style.css';
import AiAssistant from './components/AiAssistant/AiAssistant'; // Import the AiAssistant component
import SignIn from './components/SignIn/SignIn';

function App() {
  return (
    <div className="main-container">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/features" element={<Features />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/compliance" element={<Compliance />} />
          <Route path="/signup" element={<SignUp />} /> {/* Add the SignUp route */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/ai-assistant" element={<AiAssistant />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
