import React, { useState } from 'react';
import './SignIn.css'; // Ensure your styles are properly defined
import { ethers } from 'ethers';
import signin from '../../assets/signin.jpg';
import contractData from "../../contracts/contract.json";
import { Link } from 'react-router-dom';
const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [walletAddress, setWalletAddress] = useState('');

    // MetaMask connection
    const connectMetaMask = async () => {
        if (window.ethereum) {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum);
                await provider.send("eth_requestAccounts", []); // Request user's MetaMask account
                const signer = await provider.getSigner();
                const address = await signer.getAddress();
                setWalletAddress(address); // Store connected wallet address
                return signer; // Return signer for further use
            } catch (err) {
                setMessage('MetaMask connection failed. Please try again.');
                console.error("MetaMask Error:", err);
            }
        } else {
            // Check if the user is on mobile or desktop
            if (isMobile()) {
                // For mobile users without MetaMask
                setMessage('Please install MetaMask on your mobile browser or use the MetaMask app.');
                window.open('https://metamask.app.link/', '_blank'); // Open MetaMask app link for mobile
            } else {
                // For desktop users without MetaMask
                setMessage('Please install MetaMask to continue.');
                window.open('https://metamask.io/download.html', '_blank'); // Redirect to MetaMask download page
            }
        }
    };
    const isMobile = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    };
    // Handle Sign In
    const handleSignIn = async (event) => {
        event.preventDefault();

        // Connect to MetaMask
        const signer = await connectMetaMask();
        if (!signer) return; // If connection to MetaMask fails, stop execution

        try {
            // Log contract data to ensure it's in correct format
            console.log("Contract ABI: ", contractData);

            // Create a contract instance with contract ABI and address
            const contract = new ethers.Contract(
                '0xc4eCaEEE6d4b562b6d7003dd38563a2816fb2B2F',  // Replace with actual contract address
                contractData.abi, // Using ABI imported from the JSON file
                signer
            );
            console.log("Contract: ", contract); // Check the contract instance

            // Call the signIn function from your smart contract
            const result = await contract.signIn(email, password);

            // Handle the result of sign-in
            console.log("Sign-in result:", result);
            setMessage('Sign in successful!');

            // Optionally, redirect the user or perform actions post sign-in
            alert("Sign in successful!");

        } catch (err) {
            setMessage('Error during sign-in. Please try again.');
            console.error('Error during sign-in:', err);
        }
    };

    return (
        <div className="signin-container">
            <section className="left-section">
                <img src={signin} alt="MedicLedger Vision" className="signin-image" />
            </section>
            <section className="right-section">
                <div className="form-container">
                    <h2>Sign In to MedicLedger</h2>
                    {message && <p style={{ color: 'red' }}>{message}</p>} {/* Display error or success message */}
                    <form onSubmit={handleSignIn}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                        </div>
                        <button type="submit" className="cta-button-signin">Sign In</button>
                    </form>
                    {walletAddress && <p>Connected Wallet: {walletAddress}</p>}
                    <p>
                        <span>Don't have an account? </span>
                        <Link to="/signup" className="signup-link">Sign Up</Link>
                    </p>
                </div>
            </section>
        </div>
    );
};

export default SignIn;
